import { render, staticRenderFns } from "./rechargeDetail.vue?vue&type=template&id=3c765e1a&scoped=true"
import script from "./rechargeDetail.vue?vue&type=script&lang=js"
export * from "./rechargeDetail.vue?vue&type=script&lang=js"
import style0 from "./rechargeDetail.vue?vue&type=style&index=0&id=3c765e1a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c765e1a",
  null
  
)

export default component.exports