<template>
  <div class="recharge-page">
    <headerBar :title="i18n.t('recharge.title')" class="red" :showArrow="true" @back="back"></headerBar>

    <div class="crypto-deposit-form">
      <div class="deposit-info">
        <!-- 二维码 -->
        <div class="qr-code">
          <div class="qrcode" ref="qrcode" ></div>
        </div>

        <!-- 地址信息 -->
        <div class="address-info">
          <p>{{i18n.t('recharge.label8')}}：</p>
          <div class="address-container">
            <span>{{ RechargeInfo.address }}</span>
            <button @click="copyToClipboard(RechargeInfo.address)">{{i18n.t('recharge.copy')}}</button>
          </div>
        </div>
      </div>

      <!-- 充值金额输入 -->
      <div class="form-group">
        <label for="amount">{{  i18n.t("recharge.label4") }} (USDT)</label>
        <input
            type="number"
            id="amount"
            v-model="form.number"
            :placeholder="i18n.t('recharge.label4')"
            min="0.01"
            step="0.01"
            required
        />
      </div>

      <!-- 转账截图上传 -->
      <div class="form-group">
        <label for="screenshot">{{i18n.t('recharge.label6')}}</label><br />
        <van-uploader v-model="fileList" max-count="1" :after-read="afterRead" />
      </div>

      <!-- 提交按钮 -->
      <button @click="submit">{{i18n.t('recharge.btn1')}}</button>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import { doRecharge, uploadFile } from '@/api/user'
import QRCode from "qrcodejs2";
export default {
  components: {
    headerBar,
    Button,
  },
  data() {
    return {
      form: {
        number: '',
        type: 1,
        img:""
      },
      userMoney: 0,
      fastList: [50, 100, 200, 300, 500, 600, 800, 1000, 2000, 5000, 8000, 10000],
      address: '',
      totalAmount: 0,
      RechargeList: {},
      fileList: [],
      formData: {
        amount: "", // 充值金额
        screenshot: null, // 转账截图
      },
      RechargeInfo: {},
      BankInfo: {},
      type: "",
      isBank : false,
      totalAmountWait: 0,
      exchange_result: 0,

    }
  },
  computed: {
    show_collection() {
      return this.$store.state.show_collection
    },
    chile_exchange_rate(){
      return this.$store.state.chile_exchange_rate
    },
    exchange_rate() {
      return this.$store.state.exchange_rate
    },
  },
  created() {
    this.type = this.$route.query.type
    if(this.type === undefined) {
      this.type = window.localStorage.getItem("type")
    }
    console.log("this.type:",this.type)
    // type = Italia
    if(this.type === "Italia" || this.type == 'Mexico') {
      // 跳转到客服页面
      this.$router.push({
        name: 'kefu',
      })
    }
    this.form.number = this.$route.query.amount
    if (this.form.number === undefined) {
      this.form.number = window.localStorage.getItem("amount")
    }
    // this.exchange_result = parseFloat(this.$route.query.amount * this.exchange_rate).toFixed(2)
    let that = this
    switch (this.$route.query.type) {
      case "Mexico":
      case "Chile":
        this.isBank = true
        let tmp = JSON.parse(window.localStorage.getItem("BankInfo"))
          tmp.forEach(item => {
            if(item.Country === this.$route.query.type) {
              that.BankInfo = item
            }
          })
        // this.BankInfo = JSON.parse(window.localStorage.getItem("BankInfo"))

        break
      default:
        this.RechargeList = JSON.parse(window.localStorage.getItem("RechargeInfo"))
        console.log("this.RechargeList:",this.RechargeList)
          if(this.RechargeList !== null && this.RechargeList.length > 0) {
            this.RechargeInfo = this.RechargeList[0]
          }
            break
    }

  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let address = this.RechargeInfo.address
      if( this.type !== "bank") {
        let qrcode = new QRCode(this.$refs.qrcode, {
          text: address,
          width: 250,
          height: 250,
          correctLevel: QRCode.CorrectLevel.H
        })
      }
    },
    // 计算兑换金额
    exchange(exchange_rate) {
      return (this.form.number * exchange_rate).toFixed(2)
    },
    copyToClipboard(data) {
      const el = document.createElement("textarea");
      el.value = data;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert(this.i18n.t("recharge.success2"));
    },
    afterRead(file) {
      // 创建 FormData 对象
      var formData = new FormData();
      formData.append('file', file.file);
      uploadFile(formData).then(res => {
        this.form.img = res.data.url
        this.fileList = [
          { url: "/upload/" + this.form.img}
        ]
      })
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    back() {
      this.$router.go(-1)
    },
    setMoney(money) {
      this.form.number =  money
    },
    goToRecharge(){
      if(this.form.img === "") {
        this.$toast(this.i18n.t('recharge.error1'))
        return
      }
      doRecharge({
        amount:this.$route.query.amount + "",
        payment_voucher: this.form.img
      }).then(res => {
        if (res.code == 0) {
          this.$toast(this.i18n.t('recharge.success1'))
          this.$router.push({
            name: 'index',
          })
        } else {
          this.$toast(res.msg)
        }
      })
    },
    submit() {
      this.goToRecharge()
    }
  }
}
</script>

<style scoped>
.crypto-deposit-form {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.crypto-deposit-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.deposit-info {
  margin-bottom: 20px;
}

.qr-code img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.address-info {
  margin-top: 10px;
}

.address-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

input[type="number"],
input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="number"]:focus,
input[type="file"]:focus {
  outline: none;
  border-color: #4caf50;
}
</style>